

import { Component, Injector, Optional } from '@angular/core';
import { CustomPanelPartData, CUSTOM_TITLE_CMP_DATA_TOKEN } from './panel.component';


@Component({
  template: '',
})
export class CustomBasicPanelPartComponent<T = any> {

  data: CustomPanelPartData<T>;

  constructor(@Optional() protected injector: Injector) {
    try {
      this.data = this.injector.get(CUSTOM_TITLE_CMP_DATA_TOKEN);
    } catch(_) {
      // ignore excption
    }
  }

}
